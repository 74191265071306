import React, {useEffect, useRef, useState} from 'react';
import Jumbotron from "../components/Jumbotron";
import {getArticleById, getCategories, getKegiatan} from "../api/articles";
import {viewAlert} from "../SweetAlert/SweetAlert";
import KegiatanList from "../components/berita/KegiatanList";
import {Link} from "react-router-dom";
import BeritaList from "../components/berita/BeritaList";

const KegiatanContent = () => {
    const isMounted = useRef(false);
    const [kegiatan, setKegiatan] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [limit, setLimit] = useState(100);
    const [selectedNews, setSelectedNews] = useState(null);

    useEffect(() => {
        if (isMounted.current) {
            return
        }
        isMounted.current = true;
        getCategories().then((res) => {
            let idcategory = '';
            res.data.forEach((item) => {
                if (item.name === 'kegiatan' || item.name === 'Kegiatan') {
                    idcategory = item.id;
                }
            })
            return idcategory;
        }).then((id) => {
            getKegiatan(id).then((res) => {
                const resp = res.data;
                let kegiatanTemp = [];
                resp.forEach((keg) => {
                    kegiatanTemp.push(keg)
                })

                setKegiatan(kegiatanTemp);

                setTotalPages(Math.ceil(parseInt(res.totalFiltered) / limit))
            })
        }).catch(err => viewAlert("Masalah!", err, "error"));
    }, [currentPage]);

    const nextPage = () => {
        console.log(currentPage)
        setCurrentPage(currentPage + 1);
        scrollToTop()
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const prevPage = () => {
        setCurrentPage(currentPage - 1);
        scrollToTop();
    };

    const handleNewsClick = (id) => {
        try {
            getArticleById(id).then((res) => {
                setSelectedNews(res);
            })
        } catch (e) {
            viewAlert('Masalah', e, 'error')
        }
    };

    return (
        <div>
            <Jumbotron title='Kegiatan'/>
            <div
                className='grid desktop:grid-flow-row-dense desktop:grid-cols-3 phone:grid-cols-1 h-[100%] bg-white desktop:ml-20 desktop:mr-20'>
                <div className='flex items-start justify-center desktop:col-span-2'>
                    <div className='w-full mt-5'>
                        {/*List Berita*/}
                        <p className='text-3xl phone:ml-10 phone:mt-5 desktop:mb-5'>Kegiatan</p>
                        <BeritaList list={kegiatan} onNewClick={handleNewsClick}/>
                        <div className='flex items-center justify-center mt-10 mb-10'>
                            <button className='bg-gray-100 p-2 hover:bg-gray-200 rounded-lg mr-5' onClick={prevPage}
                                    disabled={currentPage === 1}>Prev
                            </button>
                            <span> {currentPage} ... {totalPages} </span>
                            <button className='bg-gray-100 p-2 hover:bg-gray-200 rounded-lg ml-5' onClick={nextPage}
                                    disabled={currentPage === totalPages}>Next
                            </button>
                        </div>

                    </div>
                </div>
                <div className='flex items-start'>
                    <div className='grid grid-cols-1 gap-5 m-10'>
                        {/*<p className='text-3xl'>Kegiatan</p>*/}
                        {/*{kegiatan.length > 0 ? (*/}
                        {/*    <div>*/}
                        {/*        <KegiatanList kegiatan={kegiatan} onNewClick={handleNewsClick}/>*/}
                        {/*        <div className='ml-2 text-blue-500'>*/}
                        {/*            <Link to={'/kegiatan'}>Kegiatan Lain >></Link>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*) : (*/}
                        {/*    <div className="max-w-sm bg-white rounded-xl overflow-hidden">*/}
                        {/*        <div className="p-6">*/}
                        {/*            <p className='text-gray-400'>Kegiatan Kosong..</p>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*)}*/}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default KegiatanContent;