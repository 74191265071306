import React, {useEffect, useState} from 'react';
import {useNavigate, useNavigation, useParams} from "react-router-dom";
import axios from "axios";
import {viewAlert} from "../SweetAlert/SweetAlert";
import DetailBerita from "../components/berita/DetailBerita";
import Loading from "../components/Loading";
import BeritaList from "../components/berita/BeritaList";
import {getArticleById, getArticles, getCategories} from "../api/articles";
import ListIklan from "../components/ListIklan";
import {getIklan} from "../api/iklan";

const DetailBeritaContent = () => {
    const { slug} = useParams();
    const [articles, setArticles] = useState(null);
    const navigator = useNavigate()
    const [iklan, setIklan] = useState([])
    const [berita, setBerita] = useState([])
    const [kegiatan, setKegiatan] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [limit, setLimit] = useState(5);
    const [selectedNews, setSelectedNews] = useState(null);

    useEffect(() => {
        getIklan().then((res) => {
            console.log(res);
            setIklan(res.data);
        })
        fetchArticles(slug).then((res) => {
            setArticles(res)
        }).catch((err) => {
            viewAlert('Masalah', err, 'error')
        })

        getCategories().then((res) => {
            let category = [];
            const responsecat = res.data;
            responsecat.forEach((data) => {
                category.push({
                    id: data.id,
                    name: data.name,
                });
            })
            return category
        }).then(async  (respn) => {
            let beritaTemp = []
            await getArticles().then((res) => {
                const response = res.data;

                response.forEach((data) => {
                    respn.forEach((category) => {
                        if (data.categoryId === category.id) {
                            data.categoryName = category.name;
                            beritaTemp.push(data)
                        }
                    })
                })

                setTotalPages(Math.ceil(parseInt(res.totalFiltered) / limit))
            }).catch((err) => {
                viewAlert('Masalah!', err, 'error')
            });

            let tempArtikle = [];
            let tempKegiatan = [];
            beritaTemp.forEach((category) => {
                if (category.slug !== slug) {
                    if (category.categoryName === "berita") {
                        tempArtikle.push(category)
                    } else if (category.categoryName === "kegiatan") {
                        tempKegiatan.push(category)
                    }
                }
            })

            setBerita(tempArtikle)
            setKegiatan(tempKegiatan)

        }).catch(err => viewAlert('Masalah!', err, 'error'))
    }, []);

    const fetchArticles = async (id) => {
        return new Promise(async (resolve, reject) => {
            await axios.get('/api/article/' + id + '?fields=id,slug,image,content,title,createdAt').then((res) => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            })
        })
    }

    const handleNewsClick = (id) => {
        try {
            getArticleById(id).then((res) => {
                console.log(res)
                setSelectedNews(res);
                navigator('/berita')
            })
        } catch (e) {
            viewAlert('Masalah', e, 'error')
        }
    };

    return (
        <div>
            <div className='grid desktop:grid-flow-row-dense desktop:grid-cols-3 phone:grid-cols-1 h-[100%] bg-white desktop:ml-20 desktop:mr-20 mb-10'>
                <div className='flex items-start justify-center desktop:col-span-2'>
                    {articles !== null ? (
                        <DetailBerita article={articles} />
                    ): (
                        <Loading />
                    )}
                </div>
                <div className='flex items-start'>
                    <div className='mt-10'>
                        {/*<ListIklan images={iklan} />*/}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DetailBeritaContent;