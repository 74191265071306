import React from 'react';

const ListIklan = ({images}) => {
    return (
        <div className='mt-5'>
            {images.map((image, index) => (
                <div id={index} key={index} className='m-10'>
                    <img src={`/${image.path}`} alt={image.name} className='w-full h-50 object-cover object-center' />
                </div>
            ))}
        </div>
    );
};

export default ListIklan;