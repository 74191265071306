import axios from "axios";
const qs = require("qs");

export const getCategories = () => {
    return new Promise(async (resolve, reject) => {

        await axios.get('/api/article/category').then((res) => {
            resolve(res.data);
        }).catch(err => {
            reject(err);
        })
    })
}

export const getArticleById = (id) => {
    return new Promise(async (resolve, reject) => {
        const obj = {
            fields: "id,slug,title,description,image,categoryId,createdAt",
            filter: {

            },
            sort: "-createdAt",
        }

        const stringQuery = qs.stringify(obj, {allowDots: true});
        await axios.get('/api/article/' + id + '' + stringQuery).then((res) => {
            resolve(res.data);
        }).catch(err => {
            reject(err);
        })
    })
}

export const getArticles = () => {
    return new Promise(async (resolve, reject) => {
        await getCategories().then(async (categories) => {
            let categoryused = [];
            categories.data.forEach(item => {
                if (item.name === "berita" || item.name === "kegiatan") {
                    categoryused.push(item);
                }
            })

            let filtercategory = ""
            categoryused.forEach(item => {
                filtercategory += item.id + ","
            })

            if (filtercategory.endsWith(",")) {
                filtercategory = filtercategory.slice(0, -1);
            }
            const obj = {
                fields: "id,slug,title,description,content,image,status,categoryId,createdAt",
                filter: {
                    "categoryId": {
                        _in: filtercategory
                    },
                    "status": "PUBLISHED"
                },
                limit: 5,
                page: 1,
                sort: "-createdAt",
            }

            const stringQuery = qs.stringify(obj, {allowDots: true});

            await axios.get('/api/article?' + stringQuery).then((res) => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            })
        })

    })
};

export const getKegiatan = (idcategory) => {
    return new Promise(async (resolve, reject) => {
        await getCategories().then(async (categories) => {
            let categoryused = [];
            categories.data.forEach(item => {
                if (item.name === "Kegiatan" || item.name === "kegiatan") {
                    categoryused.push(item);
                }
            })

            const obj = {
                fields: "id,slug,title,description,content,image,status,categoryId,createdAt",
                filter: {
                    "categoryId": {
                        _in: idcategory
                    },
                    "status": "PUBLISHED"
                },
                limit: 100,
                page: 1,
                sort: "-createdAt",
            }

            const stringQuery = qs.stringify(obj, {allowDots: true});

            await axios.get('/api/article?' + stringQuery).then((res) => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            })
        })

    })
};